header {
  text-align: center;
  font-size: 5em;
  font-family: '"Gotham SSm A", "Gotham SSm B", sans-serif';
}
.headerText {
  font-size: 25px;
  font-family: '"Gotham SSm A", "Gotham SSm B", sans-serif';
}
@media only screen and (min-width: 769px) {
  .gridContainer {
    display: grid;
    grid-template-columns: 33% 34% 33%;
    padding: 10px;
  }
  .headerText {
    margin: 100px;
  }
}
@media only screen and (max-width: 768px) {
  .gridContainer {
    display: grid;
    grid-template-columns: 100%;
    padding: 10px;
  }
  .headerText {
    margin: 40px;
  }
}
.gridItem {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
}
.gridItem > a > img {
  max-width: 100%;
}
.mainText {
  grid-column: 1 / 4;
}
